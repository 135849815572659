import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./login/login";
import Status from "./status/status";
import Setup from "./setup/setup";
import Install from "./install/install";
import NotFoundPage from "./NotFoundPage";

import "./common.css";
import scim_bridge from "./img/scim-bridge.svg";

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <div id="scim-bridge-container">
        <div id="top-bar">
          <img
            alt="SCIM Bridge Logo"
            id="scim-bridge-logo"
            className="logo"
            src={scim_bridge}
          />
        </div>
        <Switch>
          <Route path="/app" exact component={Login} />
          <Route path="/app/login" exact component={Login} />
          <Route path="/app/status" exact component={Status} />
          <Route path="/app/setup" component={Setup} />
          <Route path="/app/install" component={Install} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;

