export const unsuccessfulConnection =
  "Couldn't connect to 1Password. Confirm the SCIM bridge network settings and try again.";
export const unsuccessfulAuth =
  "Couldn't authenticate. Make sure the Provision Manager account is an active user. If the Provision Manager's credentials have changed, generate a new 'scimsession' file and Bearer Token.";

export const unhealthy = "unhealthy";
export const healthy = "healthy";

export const successfulDeployment = "Deployment successful.";
export const successfulConnection = "Connection to 1Password successful.";
export const successfulAuth = "Authentication successful.";
