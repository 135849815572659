import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TooltipInput from "./TooltipInput";
import {
  errorHandledWorkspaceRequest,
  isValidBridgeAddress,
  restartServer,
  workspaceRequest,
  Successes,
  Errors,
} from "./utils";
import "./Configurator.css";

interface ConfiguratorProps {
  sessionToken: string;
  setError: Dispatch<SetStateAction<string>>;
  setSuccesses: Dispatch<SetStateAction<string>>;
}

interface Config {
  actor?: string;
  bridgeAddress?: string;
  domain?: string;
}

export default ({
  sessionToken,
  setError,
  setSuccesses,
}: ConfiguratorProps): JSX.Element => {
  const [actor, setActor] = useState("");
  const [domain, setDomain] = useState("");
  const [bridgeAddress, setBridgeAddress] = useState("");
  const [previousConfig, setPreviousConfig] = useState<Config>({});

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const res = await workspaceRequest("GET", "settings", sessionToken);
      if (res.ok) {
        const config = await res.json();
        setPreviousConfig(config);
        setActor(config.actor);
        setDomain(config.domain);
        setBridgeAddress(config.bridgeAddress);
      }
    })();
  }, []);

  const disableSaveConfigButton =
    previousConfig.actor === actor &&
    previousConfig.bridgeAddress === bridgeAddress &&
    previousConfig.domain === domain;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidBridgeAddress(bridgeAddress)) {
      setError(Errors.InsecureBridgeAddress);
      return;
    }

    const result = await Swal.fire({
      title: "Warning",
      text: "Once your config is uploaded, this page will refresh and you will be required to log in again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Upload",
      cancelButtonText: "Cancel Upload",
      confirmButtonColor: "#0572ec",
    });

    if (result.isConfirmed) {
      const { success } = await errorHandledWorkspaceRequest(
        "POST",
        "settings",
        sessionToken,
        setError,
        JSON.stringify({ actor, domain, bridgeAddress })
      );

      if (success) {
        setSuccesses(Successes.SettingsUpdated);
        await restartServer(
          Successes.SettingsUpdated,
          sessionToken,
          setError,
          history.push
        );
      }
    }
  };

  const onDelete = async () => {
    const result = await Swal.fire({
      title: "Warning",
      text: "Deleting your config will reset your Workspace integration until you re-upload it. You will be taken back to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Deletion",
      cancelButtonText: "Cancel Deletion",
      confirmButtonColor: "#E6735C",
    });

    if (result.isConfirmed) {
      const { success } = await errorHandledWorkspaceRequest(
        "DELETE",
        "settings",
        sessionToken,
        setError
      );
      if (success) {
        setSuccesses(Successes.SettingsDeleted);
        await restartServer(
          Successes.SettingsDeleted,
          sessionToken,
          setError,
          history.push
        );
      }
    }
  };

  return (
    <div className="configurator">
      <p className="workspace-settings__status">Configuration</p>
      <form className="configurator__form" onSubmit={onSubmit}>
        <TooltipInput
          text={actor || ""}
          setText={setActor}
          placeholder="Actor"
          tooltip="Email address of the Google Workspace administrator account your Service Account will act on behalf of."
          type="email"
        />
        <TooltipInput
          text={domain || ""}
          setText={setDomain}
          placeholder="Domain"
          tooltip="The Google Workspace domain of your organization."
          type="text"
        />
        <TooltipInput
          text={bridgeAddress || ""}
          setText={setBridgeAddress}
          placeholder="Bridge Address"
          tooltip="The fully-qualified domain name of your 1Password SCIM bridge."
          type="text"
        />

        <button
          className={
            disableSaveConfigButton
              ? "workspace-settings__button workspace-settings__button--disabled"
              : "workspace-settings__button"
          }
          type="submit"
          disabled={disableSaveConfigButton}
        >
          {previousConfig ? "Update Configuration" : "Submit Configuration"}
        </button>
      </form>

      <form className="configurator__form">
        {previousConfig && (
          <button
            className="workspace-settings__button workspace-settings__button--delete"
            onClick={onDelete}
          >
            Delete Configuration
          </button>
        )}
      </form>
    </div>
  );
};
