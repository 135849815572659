import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GetBridgePath } from "../helpers/router";
import CollapsibleContent from "./components/CollapsibleContent";
import LogList from "./components/LogList";
import StatusReport from "./components/StatusReport";
import VersionInfo from "./components/VersionInfo";
import WorkspaceSettings from "./components/workspace-settings";
import * as statusMessages from "./statusmessages";
import "./status.css";
import { useHistory } from "react-router-dom";
import { useRedirect } from "../helpers/redirect";

const workspaceModeName = "workspace";

interface HealthReport {
  build: string;
  version: string;
  reports: ComponentReport[];
}

interface InfoReportDisplayProps {
  infoReport: InfoReport;
}

interface InfoReport {
  connection: boolean;
  session: boolean;
  mode: string;
  beta: boolean;
}

const InfoReportDisplay = ({ infoReport }: InfoReportDisplayProps) => (
  <>
    <StatusReport
      statusHealth={
        infoReport.connection
          ? statusMessages.healthy
          : statusMessages.unhealthy
      }
      statusName="Connection"
      statusText={
        infoReport.connection
          ? statusMessages.successfulConnection
          : statusMessages.unsuccessfulConnection
      }
    />
    <StatusReport
      statusHealth={
        infoReport.session ? statusMessages.healthy : statusMessages.unhealthy
      }
      statusName="Authentication"
      statusText={
        infoReport.session
          ? statusMessages.successfulAuth
          : statusMessages.unsuccessfulAuth
      }
    />
  </>
);

interface ComponentReportDisplayProps {
  componentReport: ComponentReport;
}

interface ComponentReport {
  source: string;
  time: Date;
  state: string;
  remaining?: string;
}

const ComponentReportDisplay = ({
  componentReport,
}: ComponentReportDisplayProps) => {
  const statusReport = (name: string) => (
    <StatusReport
      statusHealth={componentReport.state}
      statusName={name}
      statusText={`${componentReport.state} as of ${componentReport.time}`}
    />
  );

  switch (componentReport.source) {
    case "ConfirmationWatcher":
      return statusReport("Confirmation Watcher");
    case "RedisCache":
      return statusReport("Redis Cache");
    case "SCIMServer":
      return statusReport("SCIM Server");
    case "StartProvisionWatcher":
      return statusReport("Start Provision Watcher");
    case "WorkspaceServer":
      return statusReport("Workspace Server");
    default:
      return <></>;
  }
};

interface LogOutRowProps {
  setSessionToken: Dispatch<SetStateAction<string>>;
}

const LogOutRow = ({ setSessionToken }: LogOutRowProps) => {
  const onClick = () => setSessionToken("");
  return (
    <div className="row" id="logout">
      <button className="big-button" onClick={onClick}>
        Log out
      </button>
    </div>
  );
};

interface StatusProps {
  betaOverride?: boolean;
}

const Status = ({ betaOverride }: StatusProps) => {
  const state = useRedirect();
  const history = useHistory();

  const [sessionToken, setSessionToken] = useState(
    sessionStorage.getItem("session-token") || ""
  );

  const [healthReport, setHealthReport] = useState<HealthReport | null>(null);
  const [infoReport, setInfoReport] = useState<InfoReport | null>(null);
  const [logList, setLogList] = useState<string[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const fetchedHealthReport: HealthReport = await fetchStatusJSON(
          "health",
          sessionToken
        );
        setHealthReport(fetchedHealthReport);
        const fetchedLogList: string[] = await fetchStatusJSON(
          "logs",
          sessionToken
        );
        setLogList(fetchedLogList);
        const fetchedInfoReport: InfoReport = await fetchStatusJSON(
          "info",
          sessionToken
        );
        setInfoReport(fetchedInfoReport);
      } catch (err) {
        // error here means b5 couldn't auth or bridge is unauthorized
        history.push("/app");
      }
    })();
  }, []);

  useEffect(() => {
    if (sessionToken === "") {
      sessionStorage.removeItem("session-token");
      history.push("/app/login");
    }
  }, [sessionToken]);

  if (state.loading) {
    return <></>;
  }

  if (!state.sessionFileFound) {
    window.location.replace("/app/setup");
  }

  return (
    <div>
      <h2>1Password SCIM Bridge Status</h2>
      {healthReport && (
        <VersionInfo
          version={healthReport.version}
          build={healthReport.build}
        />
      )}

      <StatusReport
        statusHealth={statusMessages.healthy}
        statusName="Deployment"
        statusText={statusMessages.successfulDeployment}
      />

      {infoReport && <InfoReportDisplay infoReport={infoReport} />}

      {healthReport &&
        healthReport.reports.map((report) => (
          <ComponentReportDisplay
            key={report.source}
            componentReport={report}
          />
        ))}

      <CollapsibleContent heading="Logs">
        {logList && <LogList logs={logList} sessionToken={sessionToken} />}
      </CollapsibleContent>

      {(betaOverride || infoReport?.beta) && (
        <CollapsibleContent heading="Google Workspace Settings">
          <WorkspaceSettings
            sessionToken={sessionToken}
            workspaceEnabled={
              infoReport ? infoReport.mode === workspaceModeName : false
            }
          />
        </CollapsibleContent>
      )}

      <LogOutRow setSessionToken={setSessionToken} />
    </div>
  );
};

async function fetchStatusJSON<T>(
  path: string,
  sessionToken: string
): Promise<T> {
  const res = await GetBridgePath("/status/" + path, sessionToken);
  if (!res.ok) {
    throw res;
  }
  return await res.json();
}

export default Status;
