import React, { useState } from "react";
import "./collapsiblecontent.css";

interface CollapsibleContentProps {
  heading: string;
  children: JSX.Element | null;
}

const CollapsibleContent = ({ heading, children }: CollapsibleContentProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const onClick = () => setCollapsed(!collapsed);

  return (
    <div className="collapsible-content">
      <div className="collapsible-content__bar">
        <span onClick={onClick} className="collapsible-content__button">
          {collapsed ? "→" : "↓"}
        </span>
        <h2 className="collapsible-content__heading">{heading}</h2>
      </div>
      <div className={collapsed ? "collapsed" : "expanded"}>{children}</div>
    </div>
  );
};

export default CollapsibleContent;
