import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errorHandledWorkspaceRequest, Successes, Errors } from "./utils";

export interface SyncProgress {
  started: boolean;
  total: number;
  current: number;
  failed: number;
}

const syncStatus = (progress: SyncProgress) =>
`${progress.current} / ${
  progress.total
} users, ${progress.failed} failed`;

interface SyncButtonProps {
  sessionToken: string;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const syncRateMS = 1000;

export default ({ sessionToken, setError, setSuccess }: SyncButtonProps) => {
  const [syncProgress, setSyncProgress] = useState<SyncProgress | null>(null);

  const updateSyncProgress = async () => {
    const { success, data } = await errorHandledWorkspaceRequest(
      "GET",
      "sync",
      sessionToken,
      setError,
    );

    if (success) {
      setSyncProgress(data);
    }
  };

  useEffect(() => {
    // perform an initial fetch in case there is a sync in progress,
    // and the user refreshes the page.
    updateSyncProgress();
  }, []);

  useEffect(() => {
    if (syncProgress) {
      // once the sync starts, keep it going
      if (syncProgress.started) {
        setTimeout(updateSyncProgress, syncRateMS);
      }

      if (syncProgress.current !== 0 && syncProgress.current === syncProgress.total) {
        setSuccess(`${Successes.UserSyncComplete} (${syncStatus(syncProgress)})`)
      }

  }}, [syncProgress]);

  const onClick = async () => {
    // If the sync has started, the user is cancelling it on click
    if (syncProgress?.started) {
      const { success } = await errorHandledWorkspaceRequest(
        "DELETE",
        "sync",
        sessionToken,
        setError
      );

      if (success) {
        setSuccess(`${Successes.UserSyncCancelled} (${syncStatus(syncProgress)})`)
      }

      return;
    }

    const result = await Swal.fire({
      title: "Warning",
      text: "This will sync all of your Google Workspace users to your 1Password account, and create any users that do not currently exist.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Sync",
      cancelButtonText: "Cancel Sync",
      confirmButtonColor: "#E6735C",
    });

    if (result.isConfirmed) {
      setError(Errors.None);
      setSuccess(Successes.None);
      const { success } = await errorHandledWorkspaceRequest(
        "POST",
        "sync",
        sessionToken,
        setError
      );
      if (success) {
        await updateSyncProgress();
      }
    }
  };

  const syncPercentage =
    syncProgress?.started &&
    Math.floor((syncProgress.current / syncProgress.total) * 100);

  // gradient has to be flipped once percentage passes 50,
  // otherwise it will create an actual gradient instead of a hard line.
  const background =
    syncPercentage &&
    (syncPercentage < 50
      ? `linear-gradient(to left, white ${
          100 - syncPercentage
        }%, #1a8cff ${syncPercentage}%)`
      : `linear-gradient(to right, #1a8cff ${syncPercentage}%, white ${
          100 - syncPercentage
        }%)`);

  return (
    <button
      className="workspace-settings__button"
      onClick={onClick}
      style={
        background
          ? {
              background,
              color: "black",
            }
          : {}
      }
    >
      {syncProgress?.started ? `Cancel Sync (${syncStatus(syncProgress)})` : "Sync Users"}
    </button>
  );
};
