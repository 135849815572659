import React, { ReactElement } from "react";

interface BearerMessageProps {
  errorMsg: string;
  notifyMsg: string;
}

const BearerMessage = ({
  errorMsg,
  notifyMsg,
}: BearerMessageProps): ReactElement => {
  if (errorMsg) {
    return (
      <div id="bearer-token-status" className="row">
        <p id="bearer-token-message" className="errormsg">
          {" "}
          {errorMsg}
        </p>
      </div>
    );
  } else {
    return (
      <div id="bearer-token-status" className="row">
        <p id="bearer-token-message" className="notification">
          {" "}
          {notifyMsg}
        </p>
      </div>
    );
  }
};
export default BearerMessage;
