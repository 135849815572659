/* general helpers */

export function GetAuthHeaders(bearer: string): Record<string, string> {
	const headers = {
		Authorization: "Bearer " + bearer,
	};
	return headers;
}

export function GetBridgePathNoAuth(path: string): Promise<Response> {
	const controller = new AbortController();
	setTimeout(() => controller.abort(), 5000);

	return fetch(path, { method: "GET", signal: controller.signal });
}

export function GetBridgePath(path: string, bearer = ""): Promise<Response> {
	const controller = new AbortController();
	setTimeout(() => controller.abort(), 5000);

	return fetch(path, {
		method: "GET",
		headers: GetAuthHeaders(bearer),
		signal: controller.signal,
	});
}

export function PostBridgePath(
	path: string,
	scimsession = "",
): Promise<Response> {
	const controller = new AbortController();
	setTimeout(() => controller.abort(), 3000);

	return fetch(path, {
		headers: { "Content-Type": "application/json; charset=UTF-8" },
		method: "POST",
		body: JSON.stringify({ session: scimsession }),
		signal: controller.signal,
	});
}

export function GetAddressPrefix(domain: string): string {
	if (domain === "localhost" || domain === "127.0.0.1") {
		if (window.location.port !== "") {
			return "http://" + domain + ":" + window.location.port;
		}
		return "http://" + domain;
	}
	return window.location.protocol + "//" + domain;
}

export interface ScimResponse {
	detail: string;
	schemas: Array<string>;
}
