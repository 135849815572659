import React, { ReactElement } from "react";
import { useState } from "react";
import { GetBridgePath } from "../../helpers/router";
import "./loglist.css";

interface LogProps {
  logs: string[];
  sessionToken: string;
}

const LogList = ({ logs, sessionToken }: LogProps): ReactElement => {
  logs = logs.sort();

  const [errorLog, setErrorLog] = useState(false);

  const downLoadLogFile = (event: React.SyntheticEvent, log: string) => {
    event.stopPropagation();
    event.preventDefault();
    GetBridgePath("/status/logs/" + log, sessionToken)
      .then((convert) => {
        if (!convert.ok) {
          throw convert;
        }
        return convert.blob();
      })
      .then(function (content) {
        const blob = new Blob([content], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = log;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log("could not get log files");
        if (err.status === 401) {
          window.location.href = "/app";
        } else if (err.status === 500) {
          setErrorLog(true);
        }
      });
  };

  return (
    <div>
      {!logs && <p>No logs avaliable.</p>}
      {!errorLog ? (
        <p className="logs" id="log-files-list">
          {logs.map((log) => (
            <li key={log}>
              <button onClick={(e) => downLoadLogFile(e, log)} type="submit">
                {log}
              </button>
            </li>
          ))}
        </p>
      ) : (
        <p id="log-files-fail" className="errormsg">
          Could not retrieve log files. Ensure your cache is running and try
          again.
        </p>
      )}
    </div>
  );
};

export default LogList;
