import React, { ReactElement } from "react";
import "./versioninfo.css";

interface VersionInfoProps {
  build: string;
  version: string;
}

export const appUpdatesBaseUrl =
  "https://app-updates.agilebits.com/product_history/SCIM";

const appUpdatesUrl = (build: string) => {
  const v = build ? "#v" + build : "";
  return appUpdatesBaseUrl + v;
};

const VersionInfo = ({ build, version }: VersionInfoProps): ReactElement => (
  <small className="version-info">
    <a
      id="app-updates-link"
      className="version-info-anchor"
      href={appUpdatesUrl(build)}
      // open in new tab
      target="_blank"
      // ensure is run in separate process
      // more: https://web.dev/external-anchors-use-rel-noopener/
      rel="noopener noreferrer"
    >
      Version {version}
    </a>
  </small>
);

export default VersionInfo;
