import React, {
  ChangeEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
// Directly import Swal and react content to prevent auto injection of CSS
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2-react-content";
import "sweetalert2/dist/sweetalert2.css";
import "./WorkspaceSettings.css";
import { useHistory } from "react-router-dom";
import {
  errorHandledWorkspaceRequest,
  fileAsString,
  isValidJSON,
  restartServer,
  WorkspaceStatus,
  workspaceRequest,
  Errors,
  Successes,
} from "./utils";
import Configurator from "./Configurator";
import SyncButton from "./SyncButton";

interface WorkspaceSettingsProps {
  sessionToken: string;
  workspaceEnabled: boolean;
}

const WorkspaceSettings = ({
  sessionToken,
  workspaceEnabled,
}: WorkspaceSettingsProps) => {
  const history = useHistory();

  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [hasCredentials, setHasCredentials] = useState(false);
  const [error, setError] = useState<string>(Errors.None);
  const [success, setSuccess] = useState<string>(Successes.None)

  useEffect(() => {
    (async () => {
      const { ok } = await workspaceRequest("GET", "credentials", sessionToken);
      setHasCredentials(ok);
    })();

    return () => {
      setHasCredentials(false);
    };
  }, []);

  useEffect(() => {
    setStatusMessage(
      workspaceEnabled
        ? WorkspaceStatus.Enabled
        : WorkspaceStatus.Disabled
    );
  }, [workspaceEnabled]);

  const onUploadButtonClick = async () => {
    const result = await Swal.fire({
      title: "Warning",
      text: "Once your key file is uploaded, this page will refresh and you will be required to log in again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Upload",
      cancelButtonText: "Cancel Upload",
      confirmButtonColor: "#0572ec",
    });

    if (result.isConfirmed && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const file = e.currentTarget.files[0];
      const body = await fileAsString(file);
      if (!isValidJSON(body)) {
        setError(Errors.InvalidJson);
        return;
      }

      const { success } = await errorHandledWorkspaceRequest(
        "POST",
        "credentials",
        sessionToken,
        setError,
        body
      );

      if (success) {
        setSuccess(Successes.KeyFileUploaded)
        await restartServer(
          Successes.KeyFileUploaded,
          sessionToken,
          setError,
          history.push
        );
      }
    }
  };

  const deleteWorkspaceKey = async () => {
    const { success } = await errorHandledWorkspaceRequest(
      "DELETE",
      "credentials",
      sessionToken,
      setError
    );
    if (success) {
      setSuccess(Successes.KeyFileDeleted)
      await restartServer(
        Successes.KeyFileDeleted,
        sessionToken,
        setError,
        history.push
      );
    }
  };

  const onDeleteClick = async () => {
    const result = await Swal.fire({
      title: "Warning",
      text: "Deleting your key file will reset your Workspace integration until you re-upload it. You will be taken back to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Deletion",
      cancelButtonText: "Cancel Deletion",
      confirmButtonColor: "#E6735C",
    });

    if (result.isConfirmed) {
      await deleteWorkspaceKey();
    }
  };

  return (
    <div className="workspace-settings">
      <p className="workspace-settings__status">{statusMessage}</p>

      {workspaceEnabled && (
        <SyncButton
          sessionToken={sessionToken}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}

      <button
        className="workspace-settings__button"
        onClick={onUploadButtonClick}
      >
        {hasCredentials ? "Replace Workspace Key" : "Upload Workspace Key"}
      </button>
      <input
        className="workspace-settings__file-input"
        ref={fileInputRef}
        type="file"
        onChange={onFileInputChange}
      />

      {hasCredentials && (
        <button
          className="workspace-settings__button workspace-settings__button--delete"
          onClick={onDeleteClick}
        >
          Delete Workspace Key
        </button>
      )}

      <Configurator 
        sessionToken={sessionToken} 
        setError={setError} 
        setSuccesses={setSuccess}
      />

      {error && (
        <div 
          className="workspace-settings__text--error"
          aria-label={error}
          role="alert"
        >
          {error}
        </div>
      )}

      {success && (
        <div 
          className="workspace-settings__text--success"
          aria-label={success}
          aria-live="polite"
        >
          {success}
        </div>
      )}
    </div>
  );
};

export default WorkspaceSettings;
