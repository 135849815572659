import React, { ReactElement } from "react";
import healthy from "../../img/healthy.svg";
import unhealthy from "../../img/unhealthy.svg";
import unknown from "../../img/unknown.svg";

interface StatusReportProps {
  statusHealth: string;
  statusName: string;
  statusText: string;
}

const StatusReport = ({
  statusHealth,
  statusName,
  statusText,
}: StatusReportProps): ReactElement => {
  let healthIcon;

  switch (statusHealth) {
    case "healthy":
      healthIcon = (
        <img alt="healthy" className="healthy checked" src={healthy} />
      );

      break;
    case "unknown":
      healthIcon = <img alt="unknown" className="unknown" src={unknown} />;
      break;
    case "unhealthy":
      healthIcon = (
        <img alt="unhealthy" className="unhealthy checked" src={unhealthy} />
      );
      break;

    default:
      healthIcon = null;
  }

  return (
    <div className="row">
      <section className="status">{healthIcon}</section>
      <section className="description">
        <h2> {statusName} </h2>
        <p> {statusText}</p>
      </section>
    </div>
  );
};

export default StatusReport;
