import React, { Dispatch, SetStateAction } from "react";
import "./TooltipInput.css";

interface TooltipInputProps {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  tooltip: string;
  placeholder: string;
  type: string;
}

export default ({
  text,
  setText,
  tooltip,
  placeholder,
  type,
}: TooltipInputProps): JSX.Element => (
  <div className="tooltip-input">
    <input
      type={type}
      required
      value={text}
      onChange={(e) => setText(e.target.value.trim())}
      className="tooltip-input__input"
      placeholder={placeholder}
    />

    <div className="tooltip-input__text">
      ?<span className="tooltip-input__tooltip">{tooltip}</span>
    </div>
  </div>
);
