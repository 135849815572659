import { ReactElement } from "react";
import "./notfound.css";

const NotFoundPage = (): ReactElement => {
  return (
    <section className="container">
      <h2>Not Found</h2>
      <div>
        {" "}
        <p className="info">
          Sorry, the page you are looking for doesn't exist.
        </p>
        <button
          className="back-button"
          onClick={() => (window.location.href = "/app/login")}
        >
          Back
        </button>{" "}
      </div>
    </section>
  );
};
export default NotFoundPage;
