import { useEffect, useState } from "react";
import { GetAddressPrefix } from "./router";

interface State {
	loading: boolean;
	sessionFileFound: boolean;
}

export const useRedirect = (): State => {
	const [state, setState] = useState<State>({
		loading: true,
		sessionFileFound: false,
	});
	interface sessionFileFoundRes {
		sessionFileFound: boolean;
	}

	const buildCheckAuthRequest = (current: string): string => {
		const addressPrefix = GetAddressPrefix(current);
		return addressPrefix + "/check";
	};

	const fetchAuth = async (url: string): Promise<sessionFileFoundRes> =>
		await (await fetch(url)).json();

	useEffect(() => {
		const current = window.location.hostname;
		const url = buildCheckAuthRequest(current);

		void (async () => {
			try {
				const data = await fetchAuth(url);

				if (!data.sessionFileFound) {
					setState({ loading: false, sessionFileFound: false });
				}

				if (data.sessionFileFound) {
					setState({ loading: false, sessionFileFound: true });
				}
			} catch (error) {
				console.error("Failed to check for scimsession file: ", error);
				setState({ loading: false, sessionFileFound: false });
			}
		})();
	}, []);

	return state;
};
