import { Dispatch, SetStateAction } from "react";

export const WorkspaceStatus = {
  Enabled: "Workspace Enabled",
  Disabled: "Workspace Disabled",
};

export const Successes = {
  None: "",
  UserSyncComplete: "User Sync Complete",
  UserSyncCancelled: "User Sync Cancelled",
  SettingsUpdated: "Workspace configuration updated successfully.",
  SettingsDeleted: "Workspace settings deleted successfully.",
  KeyFileUploaded: "Workspace key file uploaded succesfully.",
  KeyFileDeleted: "Workspace key file deleted successfully.",
};

export const Errors = {
  None: "",
  InvalidJson: "Key file is not valid JSON.",
  NoServerConnection: "There was an error connecting to the server.",
  InsecureBridgeAddress:
    "Error: Invalid bridge address. An address must be of the form 'https://scim.example.com'.",
};

export interface Error {
  type: string;
  message: string;
}

export const workspaceRequest = (
  method: string,
  path: string,
  sessionToken: string,
  body?: string
) =>
  fetch("/workspace/" + path, {
    method,
    headers: {
      Authorization: "Bearer " + sessionToken,
      "Content-Type": "application/json; charset=UTF-8",
    },
    body,
  });

export const errorHandledWorkspaceRequest = async (
  method: string,
  path: string,
  sessionToken: string,
  setError: Dispatch<SetStateAction<string>>,
  body?: string
) => {
  try {
    const res = await workspaceRequest(method, path, sessionToken, body);

    if (!res.ok) {
      const { detail } = await res.json();
      setError("Error: " + detail);
      return {
        success: false,
        data: null,
      };
    }

    try {
      const json = await res.json();
      return {
        success: true,
        data: json,
      };
    } catch {
      return {
        success: true,
        data: null,
      };
    }
  } catch (err) {
    console.error(err);
    setError(Errors.NoServerConnection);
    return {
      success: false,
      data: null,
    };
  }
};

export const restartServer = async (
  loginMessage: string,
  sessionToken: string,
  setError: Dispatch<SetStateAction<string>>,
  push: (path: string, message: string) => void
) => {
  const { success } = await errorHandledWorkspaceRequest(
    "GET",
    "restart",
    sessionToken,
    setError
  );
  if (success) {
    const interval = setInterval(async () => {
      try {
        await workspaceRequest("GET", "ping", "");
        clearInterval(interval);
        push("/app/login", loginMessage);
      } catch (error) {
        // try again
      }
    }, 500);
  }
};

export const fileAsString = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;

    reader.readAsText(file);
  });
};

export const isValidJSON = (json: string) => {
  try {
    JSON.parse(json);
  } catch {
    return false;
  }
  return true;
};

// for some reason JS's protocol includes a colon
const https = "https:";
// this is a duplicate implementation as the server's validator
export const isValidBridgeAddress = (bridgeAddress: string) => {
  try {
    const url = new URL(bridgeAddress);
    return (
      bridgeAddress.includes(".") &&
      url.protocol === https &&
      url.host &&
      url.origin.length === bridgeAddress.length
    );
  } catch {
    return false;
  }
};
